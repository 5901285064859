<template>
  <svg viewBox="0 0 30 30"
       :width="size"
       :height="size"
       :x="x"
       :y="y"
       :fill="color"
       @click="$emit('click')"
  >
    <path d="M4,8 a2,2 0 0 0 -2,2 v12 a2,2 0 0 0 2,2 h4 v5.5 l6.5,-5.5 h6 a2,2 0 0 0 2,-2 v-12 a2,2 0 0 0 -2,-2 h-16z
  M4.5,9.5 h15.5 a1,1 0 0 1 1,1 v11 a1,1 0 0 1 -1,1 h-6.5 l-4,3.5 v-3.5 h-5 a1,1 0 0 1 -1,-1 v-11 a1,1 0 0 1 1,-1z"/>

    <svg viewBox="0 0 28 28" width="13" height="13" x="6" y="9">
      <path d="M2,6 h4 v-4 h3 v4 h4 v3 h-4 v4 h-3 v-4 h-4 v-3z"/>
      <path d="M15,6 h11 v3 h-11 v-3z"/>
      <g>
        <circle r="1.5" cx="7.5" cy="16.5"/>
        <path d="M2,19.5 h11 v3 h-11 v-3z"/>
        <circle r="1.5" cx="7.5" cy="25.5"/>
      </g>
      <path d="M15,18 l2,-2 l3,3 l3-3 l2,2 l-3,3 l3,3 l-2,2 l-3,-3 l-3,3 l-2,-2 l3,-3 l-3,-3z"/>
    </svg>

    <g v-if="plus">
      <path d="M22,4 h3 v-3 h2 v3 h3 v2 h-3 v3 h-2 v-3 h-3 v-2z"/>
    </g>
    <g v-if="minus">
      <path d="M22,4 h8 v2 h-8 v-2z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ScoreIcon',

  props: {
    color: {
      required: false,
      type: String,
      default: 'black',
    },
    minus: {
      required: false,
      type: Boolean,
      default: false,
    },
    plus: {
      required: false,
      type: Boolean,
      default: false,
    },
    size: {
      required: false,
      type: Number,
      default: 20,
    },
    x: {
      required: false,
      type: Number,
      default: 0,
    },
    y: {
      required: false,
      type: Number,
      default: 0,
    },
  },
}
</script>
