export default {
  methods: {
    uuidGenerate () {
      const uuidTemplate = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
      let uuid = ''

      for (const char of uuidTemplate) {
        uuid += char === 'x' ? (Math.floor(Math.random() * 16)).toString(16) : char
      }

      return uuid
    },
  },
}
