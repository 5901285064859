<template>
  <v-expansion-panel-content>
    <div class="panel__content">
      <header class="header__actions">
        <v-text-field label="Nom du score"
                      v-model="score.question"
                      outlined
        />
        <div>
          <v-btn @click="$emit('delete-score', score)" text fab>
            <score-icon :size="40" :minus="true"/>
          </v-btn>
        </div>
      </header>

      <section>
        <v-chip-group column>
          <span v-for="question in questions" :key="question.id">
            <v-tooltip :z-index="100" top v-if="displayChip(question)">
            <template v-slot:activator="{ on, attrs }">
              <v-chip color="primary"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      @click="value += `{ ${question.name} }`"
              >
                {{ question.name }}
              </v-chip>
            </template>
            <span>{{ question.question }}</span>
          </v-tooltip>
          </span>
        </v-chip-group>

        <v-text-field v-model="value"
                      label="Définition du calcul"
        />
      </section>
    </div>
  </v-expansion-panel-content>
</template>

<script>
import ScoreIcon from '@/components/Survey/ScoreIcon'

export default {
  name: 'AdminFormScore',

  components: { ScoreIcon },

  data: () => ({
    value: '',
  }),

  props: {
    score: {
      required: true,
      type: Object,
    },
    questions: {
      required: true,
      type: Array,
    },
  },

  methods: {
    displayChip ({ question, name }) {
      return question !== '' && name !== ''
    },
    onChange (event) {
      this.value = event.target.innerHTML
    },
  },
}
</script>

<style lang="scss" scoped>
@import "headerActions";

.panel__content {
  padding-top: 20px;
}
</style>
