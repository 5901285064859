<template>
  <section id="create__form">
    <h1>Créer un questionnaire</h1>

    <header class="header__actions">
      <v-text-field label="Titre du questionnaire"
                    v-model="questionnaire.title"
                    outlined
      />
      <div>
        <v-btn @click="onAddQuestion" text fab>
          <question-icon :size="40" :plus="true"/>
        </v-btn>
      </div>
    </header>

    <v-expansion-panels focusable>
      <v-expansion-panel v-for="(question, index) in questionnaire.questions"
                         :key="index"
      >
        <v-expansion-panel-header>
          <span v-html="titleQuestion(question.id)"/>
        </v-expansion-panel-header>

        <admin-form-question :question="question"
                             @add-answer="onAddAnswer"
                             @delete-answer="onDeleteAnswer"
                             @delete-question="onDeleteQuestion"
                             @update-answer-text="onUpdateAnswer('text', $event)"
                             @update-answer-value="onUpdateAnswer('value', $event)"
                             @update-question-name="onUpdateQuestion('name', $event)"
                             @update-question-question="onUpdateQuestion('question', $event)"
                             @update-question-type="onUpdateQuestion('type', $event)"
        />
      </v-expansion-panel>
    </v-expansion-panels>

    <h1>Scores</h1>
    <v-btn fab text @click="onAddScore">
      <score-icon :size="40" :plus="true"/>
    </v-btn>

    <v-expansion-panels focusable>
      <v-expansion-panel v-for="(score, index) in questionnaire.scores"
                         :key="index"
      >
        <v-expansion-panel-header>
          <span v-html="titleQuestion(score.name)"/>
        </v-expansion-panel-header>

        <admin-form-score :score="score"
                          :questions="questionnaire.questions"
                          @delete-score="onDeleteScore"
        />
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<script>
import uuidGenerator from '@/mixins/uuidGenerator'
import AdminFormScore from '@/components/Survey/Score'
import ScoreIcon from '@/components/Survey/ScoreIcon'

export default {
  name: 'AdminCreateForm',

  mixins: [uuidGenerator],

  components: {
    ScoreIcon,
    AdminFormScore,
    AdminFormQuestion: () => import('@/components/Survey/Question'),
    QuestionIcon: () => import('@/components/Survey/QuestionIcon'),
  },

  data: () => ({
    index: 0,
    questionnaire: {
      title: '',
      questions: [],
      scores: [],
    },
  }),

  methods: {
    onAddAnswer ({ id }) {
      this.questionnaire.questions.find(question => question.id === id)
          .answers
          .push({
            id: this.uuidGenerate(),
            text: '',
            value: '',
          })
    },
    onAddQuestion () {
      this.questionnaire.questions.push({
        answers: [],
        id: this.uuidGenerate(),
        name: '',
        question: '',
        type: '',
      })
    },
    onAddScore () {
      this.questionnaire.scores.push({
        id: this.uuidGenerate(),
        name: '',
        value: '',
      })
    },
    onDeleteAnswer ({ question, answer }) {
      const questionUpdated = this.questionnaire.questions.find(questionSaved => questionSaved.id === question.id)
      questionUpdated.answers = questionUpdated.answers.filter(answerSaved => answerSaved.id !== answer.id)
    },
    onDeleteQuestion ({ id }) {
      this.questionnaire.questions = this.questionnaire.questions.filter(question => question.id !== id)
    },
    titleQuestion (id) {
      const question = this.questionnaire.questions.find(question => question.id === id)

      return question && question.question !== ''
             ? question.question
             : '<span style="font-style:italic;">Pas de titre</span>'
    },
    onDeleteScore ({ id }) {
      this.questionnaire.scores = this.questionnaire.scores.filter(score => score.id !== id)
    },
    onUpdateAnswer (type, { id, questionId, value }) {
      this.questionnaire.questions.find(question => question.id === questionId)
          .answers
          .find(answer => answer.id === id)[type] = value
    },
    onUpdateQuestion (type, { id, value }) {
      this.questionnaire.questions.find(question => question.id === id)[type] = value
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";
@import "src/assets/style/backgroundPage";
@import "src/components/Survey/headerActions";

#create__form {
  @include container;
  padding: 0 20px;
}
</style>
